import React from "react";

import header from "../images/port-info/green-port/2020-header.jpg";
import aerial from "../images/port-info/green-port/2023-polb-aerial.png";

class GreenPort2023 extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <section id="year-19" className="gp-page__section gp-page__section--year-18 gutter">
        {/* <h2 className="gp-title gp-page__section--title">Year 15</h2> */}

        <h2 className="year-19 gp-page__section--year-title">2023</h2>

        <div className="gp-page__section--header-container">
          {/* <img src={aerial} alt="truck driving" className="section-image header-image year-18-image" /> */}
        </div>

        <p className="gp-text intro">The Harbor Commission establishes the Zero Emissions, Energy Resilient Operations program, or ZEERO. The initiative is aimed at investing in projects to improve air quality and reduce greenhouse gases.</p>

        <img src={aerial} alt="aerial view of the port" className="section-image" />

        <p className="gp-text intro">One of the first projects under consideration, the Floating Offshore Wind Staging and Integration facility, known as “Pier Wind,” would be the largest facility specifically designed to assemble offshore wind turbines at any U.S. seaport. Pier Wind would help California meet a goal of producing 25 gigawatts of offshore wind power by 2045.</p>

        <div className="gp-page__next-section">
          <h3 className="year-text">The Future</h3>
          <i className="next-year"></i>
        </div>
      </section>
    )
  }
}

export default GreenPort2023;