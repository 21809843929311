import React from "react";
import { Parallax } from "react-scroll-parallax";

import header from "../images/port-info/green-port/2016-header.jpg";
import community from "../images/port-info/green-port/2016-community.jpg";
import garage from "../images/port-info/green-port/2016-garage.jpg";
import secondLayer2016 from "../images/port-info/green-port/2016-layer-2.png";
import thirdLayer2016 from "../images/port-info/green-port/2016-layer-3.png";

class GreenPort2016 extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <section id="year-12" className="gp-page__section gp-page__section--year-11">
        {/* <h2 className="gp-title gp-page__section--title">Year 11</h2> */}

        <h2 className="year-12 gp-page__section--year-title">2016</h2>

        <div className="gp-page__section--header-container">
          {/* <h2 className="year-11">2016</h2> */}
          <img src={header} alt="2016 port" className="section-image header-image year-11-image" />

          <div className="parallax-container">
            <Parallax tagInner="figure" y={[0, 0]} className="parallax-container__background">
              <img src={thirdLayer2016} />
            </Parallax>
            <Parallax tagInner="figure" y={[60, -20]} className="parallax-container__midground section-11-layer-2">
              <img src={secondLayer2016} />
            </Parallax>
          </div>
        </div>

        <div className="gutter">
          <p className="gp-text intro">The world's greenest container terminal, Long Beach Container Terminal, opens. At full buildout in 2021, the terminal will have the world's largest on-dock rail yard for any terminal and by itself be a top five U.S. seaport by container volume.</p>

          <p className="gp-text intro">Harbor commissioners start the nation’s largest seaport pollution mitigation effort, the Community Grants Program. At least $46.4 million is set aside for the program to help those in the community who are most vulnerable to pollution. Combined with a previous effort, almost $65 million has been committed.</p>

          <img src={community} alt="community" className="section-image" />

          <img src={garage} alt="garage" className="section-image" />

          <p className="gp-text intro">Four buildings at LBCT earn LEED Gold status. The buildings function as administrative offices and meeting space, an information technology center, and maintenance and operations structures. High-­efficiency systems reduce power use, save water through low­-flow water systems in restrooms, reuse old asphalt and concrete wherever possible and incorporate drought-­tolerant landscaping to help reduce runoff and conserve water. Floor vents bring in outside air and allow employees to control their workspace temperature. Exterior features include special parking for fuel­-efficient vehicles and solar panels over the parking lots.</p>

          <div className="gp-page__next-section">
            <h3 className="year-text">Year 13</h3>
            <i className="next-year"></i>
          </div>
        </div>
      </section>
    )
  }
}

export default GreenPort2016;