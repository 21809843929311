import React from "react";
import { Parallax } from "react-scroll-parallax";

import counsel from "../images/port-info/green-port/2006-counsel.jpg";
import header from "../images/port-info/green-port/2006-header.jpg";
import firstLayer2006 from "../images/port-info/green-port/2006-layer-1.png";
import secondLayer2006 from "../images/port-info/green-port/2006-layer-2.png";
import thirdLayer2006 from "../images/port-info/green-port/2006-layer-3.png";

class GreenPort2006 extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <section id="year-2" className="gp-page__section gp-page__section--year-2">
        {/* <h2 className="gp-title gp-page__section--title">Year 2</h2> */}

        <h2 className="year-2 gp-page__section--year-title">2006</h2>

        <div className="gp-page__section--header-container">
          {/* <h2 className="year-2">2006</h2> */}
          <img src={header} alt="handshake" className="section-image header-image year-2-image" />
          
          <div className="parallax-container">
            <Parallax tagInner="figure" y={[0, 0]} className="parallax-container__background">
              <img src={thirdLayer2006} />
            </Parallax>
            <Parallax tagInner="figure" y={[20, -40]} className="parallax-container__midground">
              <img src={secondLayer2006} />
            </Parallax>
            <Parallax tagInner="figure" y={[200, -60]} className="parallax-container__foreground section-2-layer-1">
              <img src={firstLayer2006} />
            </Parallax>
          </div>
        </div>

        <div className="gutter">
          <img src={counsel} alt="counsel" className="section-image" />

          <p className="gp-text intro">The Port of Long Beach partners with the Port of Los Angeles to adopt the San Pedro Bay Ports Clean Air Action Plan (CAAP), a landmark air quality plan with comprehensive, far-reaching strategies to reduce port-related pollution.</p>

          <p className="gp-text intro">International Transportation Service, SSA Marine and Matson Navigation Co. all sign "green leases," pioneering agreements with shore power requirements to significantly reduce emissions ahead of other ports and regulatory mandates.</p>

          <div className="gp-page__next-section">
            <h3 className="year-text">Year 3</h3>
            <i className="next-year"></i>
          </div>
        </div>
      </section>
    )
  }
}

export default GreenPort2006;