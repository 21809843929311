import React from "react";
import { Parallax } from "react-scroll-parallax";

import plug from "../images/port-info/green-port/2008-plug.jpg";
import header from "../images/port-info/green-port/2008-header.jpg";
import secondLayer2008 from "../images/port-info/green-port/2008-layer-2.png";
import thirdLayer2008 from "../images/port-info/green-port/2008-layer-3.png";

class GreenPort2008 extends React.Component {
  constructor(props) {
    super(props);
    // this.handleWindowSizeChange = this.handleWindowSizeChange.bind(this);
    // this.layerTwoOffset = this.layerTwoOffset.bind(this);
  }

  // state = {
  //   windowWidth: null
  // }

  // componentDidMount() {
  //   if (typeof window !== 'undefined') {
  //     this.handleWindowSizeChange();
  //     window.addEventListener('resize', this.handleWindowSizeChange);
  //   }
  // }
  // componentWillUnmount() {
  //   window.removeEventListener('resize', this.handleWindowSizeChange);
  // }

  // handleWindowSizeChange() {
  //   this.setState({ windowWidth: window.innerWidth });
  // }

  // layerTwoOffset(windowWidth) {
  //   let coordinateArray;

  //   if (windowWidth < 912) {
  //     coordinateArray = [10, 0];
  //   } else if (windowWidth >= 912) {
  //     coordinateArray = [25, 0];
  //   }

  //   return coordinateArray;
  // }

  render() {
    return (
      <section id="year-4" className="gp-page__section gp-page__section--year-4">
        {/* <h2 className="gp-title gp-page__section--title">Year 4</h2> */}

        <h2 className="year-4 gp-page__section--year-title">2008</h2>

        <div className="gp-page__section--header-container">
          <img src={header} alt="truck driver" className="section-image header-image year-4-image" />
          
          <div className="parallax-container">
            <Parallax tagInner="figure" y={[0, 0]} className="parallax-container__background">
              <img src={thirdLayer2008} />
            </Parallax>
            <Parallax tagInner="figure" y={[60, 0]} className="parallax-container__midground section-3-layer-2">
              <img src={secondLayer2008} />
            </Parallax>
          </div>
        </div>

        <div className="gutter">
          <p className="gp-text intro">The Port’s Clean Trucks Program implements a ban on older, heavy polluting diesel drayage trucks, eventually reducing emissions from the vehicles by 90%.</p>

          <div className="gp-page__section--text-img-overlap left-overlap">
            <p className="gp-text intro">First shore power hookup for container ships is installed at Pier G.</p>
            <img src={plug} alt="cargo ship" className="section-image" />
          </div>

          <div className="gp-page__next-section">
            <h3 className="year-text">Year 5</h3>
            <i className="next-year"></i>
          </div>
        </div>
      </section>
    )
  }
}

export default GreenPort2008;