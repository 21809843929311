import React from "react";

import header from "../images/port-info/green-port/2020-header.jpg";

class GreenPort2020 extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <section id="year-16" className="gp-page__section gp-page__section--year-15 gutter">
        {/* <h2 className="gp-title gp-page__section--title">Year 15</h2> */}

        <h2 className="year-16 gp-page__section--year-title">2020</h2>

        <div className="gp-page__section--header-container">
          <img src={header} alt="truck driving" className="section-image header-image year-15-image" />
        </div>

        <p className="gp-text intro">The Boards of Harbor Commissioners for Long Beach and Los Angeles meet jointly to approve a resolution identifying the Clean Truck Fund Rate to incentivize the use of zero or near-zero emissions drayage trucks.</p>

        <div className="gp-page__next-section">
          <h3 className="year-text">Year 17</h3>
          <i className="next-year"></i>
        </div>
      </section>
    )
  }
}

export default GreenPort2020;