import React from "react";
import { Parallax } from "react-scroll-parallax";

import header from "../images/port-info/green-port/2019-header.jpg";
import park from "../images/port-info/green-port/2019-park.jpg";
import secondLayer2019 from "../images/port-info/green-port/2019-layer-2.png";
import thirdLayer2019 from "../images/port-info/green-port/2019-layer-3.png";

class GreenPort2019 extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <section id="year-15" className="gp-page__section gp-page__section--year-14">
        {/* <h2 className="gp-title gp-page__section--title">Year 14</h2> */}

        <h2 className="year-15 gp-page__section--year-title">2019</h2>

        <div className="gp-page__section--header-container">
          {/* <h2 className="year-14">2019</h2> */}
          <img src={header} alt="port offices" className="section-image header-image year-14-image" />

          <div className="parallax-container">
            <Parallax tagInner="figure" y={[0, 0]} className="parallax-container__background">
              <img src={thirdLayer2019} />
            </Parallax>
            <Parallax tagInner="figure" y={[60, -20]} className="parallax-container__midground section-14-layer-2">
              <img src={secondLayer2019} />
            </Parallax>
          </div>
        </div>

        <div className="gutter">
          <p className="gp-text intro">The new Port Administration Building opens. Constructed to the LEED Gold standard, the building's features include photovoltaic panels that offset 25% of electricity consumption, an exterior that maximizes natural light exposure during the day, an underfloor air distribution system to reduce energy used for heating and cooling, and cisterns to manage stormwater runoff and irrigate the site.</p>

          <img src={park} alt="sunny day at the park" className="section-image" />

          <p className="gp-text intro">Harbor commissioners vote to fund a $26.3 million project to improve water quality of the Colorado Lagoon in Long Beach. The Port will receive environmental mitigation credits that would allow future development in the harbor.</p>

          <div className="gp-page__next-section">
            <h3 className="year-text">Year 16</h3>
            <i className="next-year"></i>
          </div>
        </div>
      </section>
    )
  }
}

export default GreenPort2019;