import React from "react";

import header from "../images/port-info/green-port/2009-header.jpg";
import moneyTrees from "../images/port-info/green-port/2009-money-trees.jpg";
import ribbon from "../images/port-info/green-port/2009-ribbon.jpg";
import boat from "../images/port-info/green-port/2009-boat.jpg";

class GreenPort2009 extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <section id="year-5" className="gp-page__section gp-page__section--year-5 gutter">
        {/* <h2 className="gp-title gp-page__section--title">Year 5</h2> */}

        <h2 className="year-5 gp-page__section--year-title">2009</h2>

        <div className="gp-page__section--header-container">
          <img src={header} alt="crane wildlife" className="section-image header-image year-5-image" />
        </div>

        <p className="gp-text intro">The ports of Long Beach and Los Angeles adopt the Water Resources Action Plan, a planning document outlining programs to improve water and sediment quality within the harbor complex.</p>

        <div className="gp-page__section--content right-image">
          <img src={moneyTrees} alt="money for trees" className="section-image" />
          <p className="gp-text intro">Community Mitigation Grant programs start to address health impacts and reduce greenhouse gases.</p>
        </div>

        <div className="gp-page__section--content left-image">
          <img src={boat} alt="2009 boat" className="section-image" />
          <p className="gp-text intro">Foss' Carolyn Dorothy hybrid tug, the world’s first, begins service at the Port of Long Beach.</p>
        </div>

        <div className="gp-page__section--content right-image">
          <img src={ribbon} alt="ribbon" className="section-image" />
          <p className="gp-text intro">The Alaskan Navigator oil tanker docks at Pier T and plugs into shore side power. It is the first in the world for an oil terminal.</p>
        </div>

        <div className="gp-page__next-section">
          <h3 className="year-text">Year 6</h3>
          <i className="next-year"></i>
        </div>
      </section>
    )
  }
}

export default GreenPort2009;