import React from "react";

import header from "../images/port-info/green-port/2020-header.jpg";

class GreenPort2022 extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <section id="year-18" className="gp-page__section gp-page__section--year-17 gutter">
        {/* <h2 className="gp-title gp-page__section--title">Year 15</h2> */}

        <h2 className="year-18 gp-page__section--year-title">2022</h2>

        <div className="gp-page__section--header-container">
          {/* <img src={header} alt="truck driving" className="section-image header-image year-17-image" /> */}
        </div>

        <p className="gp-text intro">Officials from the cities and ports of Long Beach and Los Angeles gathered to launch the Clean Truck Fund rate to support the changeover to cleaner trucks. It is expected to generate $90 million in the first year, or $45 million for each port.</p>

        <p className="gp-text intro">Later in the year, the Port announced a trucking company partner will convert to a full zero-emissions fleet in 2025 and infrastructure to charge 60 vehicles at its Port location.</p>

        <p className="gp-text intro">First publicly accessible truck charging station of any U.S. seaport opened in November.</p>

        <div className="gp-page__next-section">
          <h3 className="year-text">Year 19</h3>
          <i className="next-year"></i>
        </div>
      </section>
    )
  }
}

export default GreenPort2022;