import React from "react";
import { Parallax } from "react-scroll-parallax";

import header from "../images/port-info/green-port/2010-header.jpg";
import firstLayer2010 from "../images/port-info/green-port/2010-layer-1.png";
import secondLayer2010 from "../images/port-info/green-port/2010-layer-2.png";
import thirdLayer2010 from "../images/port-info/green-port/2010-layer-3.png";

class GreenPort2010 extends React.Component {
  constructor(props) {
    super(props);
    // this.handleWindowSizeChange = this.handleWindowSizeChange.bind(this);
    // this.layerTwoOffset = this.layerTwoOffset.bind(this);
    // this.layerThreeOffset = this.layerThreeOffset.bind(this);
  }

  // state = {
  //   windowWidth: null
  // }

  // componentDidMount() {
  //   if (typeof window !== 'undefined') {
  //     this.handleWindowSizeChange();
  //     window.addEventListener('resize', this.handleWindowSizeChange);
  //   }
  // }
  // componentWillUnmount() {
  //   window.removeEventListener('resize', this.handleWindowSizeChange);
  // }

  // handleWindowSizeChange() {
  //   this.setState({ windowWidth: window.innerWidth });
  // }

  // layerTwoOffset(windowWidth) {
  //   let coordinateArray;

  //   if (windowWidth < 912) {
  //     coordinateArray = [70, 40];
  //   } else if (windowWidth >= 912) {
  //     coordinateArray = [80, 50];
  //   } else if (windowWidth >= 1075) {
  //     coordinateArray = [100, 85];
  //   }

  //   return coordinateArray;
  // }

  // layerThreeOffset(windowWidth) {
  //   let coordinateArray;

  //   if (windowWidth < 912) {
  //     coordinateArray = [60, -20];
  //   } else if (windowWidth >= 1075) {
  //     coordinateArray = [75, 25];
  //   }

  //   return coordinateArray;
  // }

  render() {
    return (
      <section id="year-6" className="gp-page__section gp-page__section--year-6">
        {/* <h2 className="gp-title gp-page__section--title">Year 6</h2> */}

        <h2 className="year-6 gp-page__section--year-title">2010</h2>

        <div className="gp-page__section--header-container">
          {/* <h2 className="year-6">2010</h2> */}
          <img src={header} alt="community" className="section-image header-image year-6-image" />

          <div className="parallax-container">
            <Parallax tagInner="figure" y={[0, 0]} className="parallax-container__background">
              <img src={thirdLayer2010} />
            </Parallax>
            <Parallax tagInner="figure" y={[30, 10]} className="parallax-container__midground section-5-layer-2">
              <img src={secondLayer2010} />
            </Parallax>
            <Parallax tagInner="figure" y={[20, -60]} className="parallax-container__foreground section-5-layer-1">
              <img src={firstLayer2010} />
            </Parallax>
          </div>
        </div>

        <div className="gutter">
          <p className="gp-text intro">About 10,000 people attend the fourth annual Port of Long Beach Green Port Fest, an exhibition of green programs and projects with guided boat tours of the harbor and train rides to see on-dock rail operations that shift cargo away from trucks to trains, reducing pollution.</p>

          <p className="gp-text intro">The CAAP is updated and new planning goals, including targets for emissions reductions for the years 2014 and 2023, are identified.</p>

          <div className="gp-page__next-section">
            <h3 className="year-text">Year 7</h3>
            <i className="next-year"></i>
          </div>
        </div>
      </section>
    )
  }
}

export default GreenPort2010;