import React from "react";
import { Parallax } from "react-scroll-parallax";

import header from "../images/port-info/green-port/2012-header.jpg";
import boats from "../images/port-info/green-port/2012-boats.jpg";
import firstLayer2012 from "../images/port-info/green-port/2012-layer-1.png";
import secondLayer2012 from "../images/port-info/green-port/2012-layer-2.png";
import thirdLayer2012 from "../images/port-info/green-port/2012-layer-3.png";

class GreenPort2012 extends React.Component {
  constructor(props) {
    super(props);
    // this.handleWindowSizeChange = this.handleWindowSizeChange.bind(this);
    // this.layerTwoOffset = this.layerTwoOffset.bind(this);
  }

  // state = {
  //   windowWidth: null,
  //   layerTwoCoordinates: []
  // }

  // componentDidMount() {
  //   if (typeof window !== 'undefined') {
  //     this.handleWindowSizeChange();
  //     // window.addEventListener('resize', this.handleWindowSizeChange);
  //   }
  // }
  // // componentWillUnmount() {
  // //   window.removeEventListener('resize', this.handleWindowSizeChange);
  // // }

  // handleWindowSizeChange() {
  //   this.setState({ windowWidth: window.innerWidth });
  // }

  // layerTwoOffset(windowWidth) {
  //   let coordinateArray;

  //   if (windowWidth < 912) {
  //     coordinateArray = [100, 20];
  //   } else if (windowWidth >= 1075) {
  //     coordinateArray = [135, 65];
  //   }

  //   return coordinateArray;
  // }

  render() {
    return (
      <section id="year-8" className="gp-page__section gp-page__section--year-8">
        {/* <h2 className="gp-title gp-page__section--title">Year 8</h2> */}

        <h2 className="year-8 gp-page__section--year-title">2012</h2>

        <div className="gp-page__section--header-container">
          {/* <h2 className="year-8">2012</h2> */}
          <img src={header} alt="clean trucks" className="section-image header-image year-8-image" />

          <div className="parallax-container">
            <Parallax tagInner="figure" y={[0, 0]} className="parallax-container__background">
              <img src={thirdLayer2012} />
            </Parallax>
            <Parallax tagInner="figure" y={[20, -80]} className="parallax-container__midground section-7-layer-2">
              <img src={secondLayer2012} />
            </Parallax>
            <Parallax tagInner="figure" y={[0, 0]} className="parallax-container__foreground section-7-layer-1">
              <img src={firstLayer2012} />
            </Parallax>
          </div>
        </div>

        <div className="gutter">
          <p className="gp-text intro">The Clean Truck Program permanently bans the last remaining, older, more polluting trucks from Port terminals.</p>

          <img src={boats} alt="clean boats" className="section-image" />

          <p className="gp-text intro">The Green Ship Program is adopted. Under the program, ships can get thousands of dollars in incentives per call if they are powered by cleaner engines, reducing emissions such as diesel particulate matter and nitrogen oxides.</p>

          <p className="gp-text intro">Sediment from a federal dredging project at Marina del Rey and Newport Harbor is marked for reuse as fill material for the terminal under construction at LBCT. The sediment eliminates the need to dredge up new material for the project, and the need to dispose of those other harbors’ sediment at sea.</p>

          <div className="gp-page__next-section">
            <h3 className="year-text">Year 9 & 10</h3>
            <i className="next-year"></i>
          </div>
        </div>
      </section>
    )
  }
}

export default GreenPort2012;