import React from "react";

import header from "../images/port-info/green-port/2011-header.jpg";
import cranes from "../images/port-info/green-port/2011-cranes.jpg";
import port from "../images/port-info/green-port/2011-port.jpg";

class GreenPort2011 extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <section id="year-7" className="gp-page__section gp-page__section--year-7 gutter">
        {/* <h2 className="gp-title gp-page__section--title">Year 7</h2> */}

        <h2 className="year-7 gp-page__section--year-title">2011</h2>

        <div className="gp-page__section--header-container">
          <img src={header} alt="75% reduction" className="section-image header-image year-7-image" />
        </div>

        <p className="gp-text intro">According to the Port's annual emissions inventory, diesel particulate matter has been reduced by 75%, exceeding the 2014 goal three years early.</p>

        <div className="gp-page__section--content right-image">
          <img src={cranes} alt="crane lifting cargo" className="section-image" />
          <p className="gp-text intro">Construction begins on Long Beach Container Terminal. The state-of-the-art terminal will be nearly all-electric, with automated stacking cranes and guided vehicles to handle cargo. Orient Overseas Container Line signs a 40-year, $4.6 billion lease to be the primary tenant.</p>
        </div>

        <img src={port} alt="2011 port" className="section-image" />

        <p className="gp-text intro">The operations building at International Transportation Service's Pier G terminal earns the U.S. Green Building Council's Leadership in Energy and Environmental Design program's Gold certification. It is equipped with energy-saving and water-efficient features and made of some recycled materials.</p>

        <div className="gp-page__next-section">
          <h3 className="year-text">Year 8</h3>
          <i className="next-year"></i>
        </div>
      </section>
    )
  }
}

export default GreenPort2011;