import React from "react";
import { Parallax } from "react-scroll-parallax";

import greetings from "../images/port-info/green-port/2005-greetings.jpg";
import header from "../images/port-info/green-port/2005-header.jpg";
import boats from "../images/port-info/green-port/2005-boats.jpg";
import leaves from "../images/port-info/green-port/2005-leaves.jpg";

import firstLayer2005 from "../images/port-info/green-port/2005-layer-1.png";
import secondLayer2005 from "../images/port-info/green-port/2005-layer-2.png";
import thirdLayer2005 from "../images/port-info/green-port/2005-layer-3.png";

class GreenPort2005 extends React.Component {
  constructor(props) {
    super(props);
    // this.handleWindowSizeChange = this.handleWindowSizeChange.bind(this);
    // this.layerTwoOffset = this.layerTwoOffset.bind(this);
    // this.layerThreeOffset = this.layerThreeOffset.bind(this);
  }

  // state = {
  //   windowWidth: null
  // }

  // componentDidMount() {
  //   if (typeof window !== 'undefined') {
  //     this.handleWindowSizeChange();
  //     window.addEventListener('resize', this.handleWindowSizeChange);
  //   }
  // }
  // componentWillUnmount() {
  //   window.removeEventListener('resize', this.handleWindowSizeChange);
  // }

  // handleWindowSizeChange() {
  //   this.setState({ windowWidth: window.innerWidth });
  // }

  // layerTwoOffset(windowWidth) {
  //   let coordinateArray;

  //   if (windowWidth >= 1075) {
  //     coordinateArray = [5, -5];
  //   }

  //   return coordinateArray;
  // }

  // layerThreeOffset(windowWidth) {
  //   let coordinateArray;

  //   if (windowWidth >= 1075) {
  //     coordinateArray = [10, -15];
  //   }

  //   return coordinateArray;
  // }

  render() {
    return (
      <section id="year-1" className="gp-page__section gp-page__section--year-1">
        {/* <h2 className="gp-title gp-page__section--title">Year 1</h2> */}

        <h2 className="year-1 gp-page__section--year-title">2005</h2>

        <div className="gp-page__section--header-container">
          {/* <h2 className="year-1">2005</h2> */}
          <img src={header} alt="Green Port policy" className="section-image header-image year-1-image" />

          <div className="parallax-container">
            <Parallax tagInner="figure" y={[0, 0]} className="parallax-container__background">
              <img src={thirdLayer2005} alt="header layer 3" />
            </Parallax>
            <Parallax tagInner="figure" y={[10, -10]} className="parallax-container__midground">
              <img src={secondLayer2005} alt="header layer 2" />
            </Parallax>
            <Parallax tagInner="figure" y={[25, -25]} className="parallax-container__foreground">
              <img src={firstLayer2005} alt="header layer 1" />
            </Parallax>
          </div>
        </div>

        <div className="gutter">
          <p className="gp-text intro">Long Beach Mayor Beverly O'Neill responds by appointing Harbor Commissioners committed to focusing on the community. They adopt the Green Port Policy, a promise to San Pedro Bay residents for a cleaner harbor, soil and skies.</p>

          <p className="gp-ending-text intro">The five guiding principles of the Green Port Policy:</p>

          <div className="five-principles">
            <div className="principles-container">
              <div className="principle principle-1">
                <span>1.</span>
                <p>Protect the community from harmful environmental impacts of Port operations.</p>
              </div>
              <div className="principle principle-2">
                <span>2.</span>
                <p>Distinguish the Port as a leader in environmental stewardship and compliance.</p>
              </div>
              <div className="principle principle-3">
                <span>3.</span>
                <p>Promote sustainability.</p>
              </div>
              <div className="principle principle-4">
                <span>4.</span>
                <p>Employ best available technology to avoid or reduce environmental impacts.</p>
              </div>
              <div className="principle principle-5">
                <span>5.</span>
                <p>Engage and educate the community.</p>
              </div>

              <img src={leaves} alt="Falling leaves" className="section-image" />
            </div>
          </div>

          <img src={boats} alt="Ships" className="section-image" />

          <p className="gp-text intro">Long Beach Harbor Commissioners adopt the Green Flag Vessel Speed Reduction Program. The program offers tiered financial incentives for ships that slow down to 12 knots or less within 20 and 40 nautical miles of Point Fermin. The program helps to cut air pollution such as smog-forming nitrogen oxides, diesel particulate matter and greenhouse gases.</p>

          <img src={greetings} alt="Greetings from Bolsa Chica" className="section-image minimize" />

          <p className="gp-text intro">The Harbor Commission agrees to contribute $11.4 million to restore the Bolsa Chica Wetlands, an ecologically important saltwater marsh in Huntington Beach. Along with a previous expenditure in 1997, the Port provided more than $50 million for the successful habitat restoration project. The Port receives mitigation credits toward future development.</p>

          <div className="gp-page__next-section">
            <h3 className="year-text">Year 2</h3>
            <i className="next-year"></i>
          </div>
        </div>
      </section>
    )
  }
}

export default GreenPort2005;