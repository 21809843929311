import React from "react";

import header from "../images/port-info/green-port/2018-header.jpg";

class GreenPort2018 extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <section id="year-14" className="gp-page__section gp-page__section--year-13 gutter">
        {/* <h2 className="gp-title gp-page__section--title">Year 13</h2> */}

        <h2 className="year-14 gp-page__section--year-title">2018</h2>

        <div className="gp-page__section--header-container">
          <img src={header} alt="best green seaport graphic" className="section-image header-image year-13-image" />
        </div>

        <p className="gp-text intro">The Port of Long Beach is named “Best Green Seaport” by industry peers.</p>

        <p className="gp-text intro">The Port is awarded over $70 million in total grant funding to move ahead with multiyear projects to support the development and use of electric power for operations. Among other projects: electric rubber-tire gantry cranes and yard tractors, and a microgrid at the Joint Command and Control Center.</p>

        <p className="gp-text intro">Due to continued improvements in water and habitat quality, the 2018 San Pedro Bay Biological Survey recorded the highest species diversity recorded since harbor-wide surveys began in 2000. More intensive surveys of the 100+ acres of kelp forest habitat within the harbor documented several new species, including garibaldi, horn sharks, and three species of abalone, which are usually associated with pristine marine habitats.</p>

        <div className="gp-page__next-section">
          <h3 className="year-text">Year 15</h3>
          <i className="next-year"></i>
        </div>
      </section>
    )
  }
}

export default GreenPort2018;