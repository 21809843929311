import React from "react";
import { Link } from "gatsby";
import Helmet from "react-helmet";
import VisabilitySensor from 'react-visibility-sensor';
import { ParallaxProvider } from "react-scroll-parallax";
import "src/styles/polb.scss";

import GreenPort2005 from "../../components/greenport-2005";
import GreenPort2006 from "../../components/greenport-2006";
import GreenPort2007 from "../../components/greenport-2007";
import GreenPort2008 from "../../components/greenport-2008";
import GreenPort2009 from "../../components/greenport-2009";
import GreenPort2010 from "../../components/greenport-2010";
import GreenPort2011 from "../../components/greenport-2011";
import GreenPort2012 from "../../components/greenport-2012";
import GreenPort2014 from "../../components/greenport-2014";
import GreenPort2015 from "../../components/greenport-2015";
import GreenPort2016 from "../../components/greenport-2016";
import GreenPort2017 from "../../components/greenport-2017";
import GreenPort2018 from "../../components/greenport-2018";
import GreenPort2019 from "../../components/greenport-2019";
import GreenPort2020 from "../../components/greenport-2020";
import GreenPort2021 from "../../components/greenport-2021";
import GreenPort2022 from "../../components/greenport-2022";
import GreenPort2023 from "../../components/greenport-2023";

import Icons from "src/components/icons";
import header from "../../images/port-info/green-port/intro-header.jpg";
import futureWildlife from "../../images/port-info/green-port/future-wildlife.png";
import futureFooter from "../../images/port-info/green-port/future-footer-image.jpg";

import layer1 from "../../images/port-info/green-port/header-layer-1.png";
import layer2 from "../../images/port-info/green-port/header-layer-2.png";
import layer3 from "../../images/port-info/green-port/header-layer-3.png";

class GreenPort extends React.Component {
  constructor(props) {
    super(props);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.sectionSwitch = this.sectionSwitch.bind(this);
    this.toggleNav = this.toggleNav.bind(this);
    // this.beforeSection = this.beforeSection.bind(this);
    // this.todaySection = this.todaySection.bind(this);
    // this.yearOneSection = this.yearOneSection.bind(this);
    // this.yearTwoSection = this.yearTwoSection.bind(this);
    // this.yearThreeSection = this.yearThreeSection.bind(this);
    // this.yearFourSection = this.yearFourSection.bind(this);
    // this.yearFiveSection = this.yearFiveSection.bind(this);
    // this.yearSixSection = this.yearSixSection.bind(this);
    // this.yearSevenSection = this.yearSevenSection.bind(this);
    // this.yearEightSection = this.yearEightSection.bind(this);
    // this.yearNineSection = this.yearNineSection.bind(this);
    // this.yearTenSection = this.yearTenSection.bind(this);
    // this.yearElevenSection = this.yearElevenSection.bind(this);
    // this.yearTwelveSection = this.yearTwelveSection.bind(this);
    // this.yearThirteenSection = this.yearThirteenSection.bind(this);
    // this.yearFourteenSection = this.yearFourteenSection.bind(this);
    // this.yearFifteenSection = this.yearFifteenSection.bind(this);
    // this.yearSixteenSection = this.yearSixteenSection.bind(this);
    // this.yearSeventeenSection = this.yearSeventeenSection.bind(this);
    // this.yearEighteenSection = this.yearEighteenSection.bind(this);
    // this.futureSection = this.futureSection.bind(this);
    this.formatSection = this.formatSection.bind(this);
    this.getElementsInArea = this.getElementsInArea.bind(this);
    this.scrollStatus = this.scrollStatus.bind(this);
  }

  state = {
    section: "today",
    mobileNavOpen: false,
    mobileView: false
  }

  componentDidMount() {
    this.updateDimensions();

    if (typeof window !== 'undefined') {
      window.addEventListener("resize", this.updateDimensions);
      window.addEventListener("scroll", this.scrollStatus);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
    window.removeEventListener("scroll", this.scrollStatus);
    window.document.body.classList.remove("hidden-scroll");
  }

  updateDimensions() {
    if (window.innerWidth < 1075) {
      this.setState({ mobileView: true });
    } else {
      this.setState({ mobileView: false });
    }
  }

  scrollStatus() {
    let elements = Array.from(document.querySelectorAll(".gp-page__section--year-title"));
    let todaySection = document.querySelector("#today .gp-title");
    let beforeSection = document.querySelector("#before .gp-title");
    let futureSection = document.querySelector("#future .gp-title");
    
    elements.unshift(todaySection, beforeSection);
    elements.push(futureSection);

    this.getElementsInArea({
      elements,
      zone: [20, 20] // percentage distance from top & bottom
    });
  }

  getElementsInArea(obj) {
    let viewportHeight = window.innerHeight;
    let elm;
    let elmYear;
    let pos;
    let topPerc;
    let bottomPerc;
    let middle;
    let inViewport;

    for (let i = 0; i < obj.elements.length; i++) {
      elm = obj.elements[i];
      elmYear = elm.classList[0];
      pos = elm.getBoundingClientRect();
      topPerc = pos.top / viewportHeight * 100;
      bottomPerc = pos.bottom / viewportHeight * 100;
      middle = (topPerc + bottomPerc) / 2;
      inViewport = middle > obj.zone[1] && middle < (100 - obj.zone[1]);

      if (inViewport) {
        this.setState({ section: elmYear });
      }
    }
  }

  sectionSwitch(e) {
    let element = e.target;
    let section;

    if (element.innerText === "Today" || element.innerText === "Before") {
      section = element.innerText.toLowerCase();
    } else if (element.innerText === "The Future") {
      section = "future";
    } else {
      let sectionArray = element.innerText.split(" ");
      section = `year-${sectionArray[1]}`;
    }

    this.setState({ mobileNavOpen: false, section }, () => {
      window.document.body.classList.remove("hidden-scroll");
    });
  }

  toggleNav() {
    this.setState({ mobileNavOpen: !this.state.mobileNavOpen }, () => {
      if (this.state.mobileNavOpen) {
        if (typeof window !== 'undefined') {
          window.document.body.classList.add("hidden-scroll");
        }
      } else {
        if (typeof window !== 'undefined') {
          window.document.body.classList.remove("hidden-scroll");
        }
      }
    });
  }

  // todaySection(isVisible) {
  //   if (isVisible) {
  //     this.setState({ section: "today" });
  //   }
  // }

  // beforeSection(isVisible) {
  //   if (isVisible) {
  //     this.setState({ section: "before" });
  //   }
  // }

  // yearOneSection(isVisible) {
  //   if (isVisible) {
  //     this.setState({ section: "year-0" });
  //   }
  // }

  // yearTwoSection(isVisible) {
  //   if (isVisible) {
  //     this.setState({ section: "year-1" });
  //   }
  // }

  // yearThreeSection(isVisible) {
  //   if (isVisible) {
  //     this.setState({ section: "year-2" });
  //   }
  // }

  // yearFourSection(isVisible) {
  //   if (isVisible) {
  //     this.setState({ section: "year-3" });
  //   }
  // }

  // yearFiveSection(isVisible) {
  //   if (isVisible) {
  //     this.setState({ section: "year-4" });
  //   }
  // }

  // yearSixSection(isVisible) {
  //   if (isVisible) {
  //     this.setState({ section: "year-5" });
  //   }
  // }

  // yearSevenSection(isVisible) {
  //   if (isVisible) {
  //     this.setState({ section: "year-6" });
  //   }
  // }

  // yearEightSection(isVisible) {
  //   if (isVisible) {
  //     this.setState({ section: "year-7" });
  //   }
  // }

  // yearNineSection(isVisible) {
  //   if (isVisible) {
  //     this.setState({ section: "year-8" });
  //   }
  // }

  // yearTenSection(isVisible) {
  //   if (isVisible) {
  //     this.setState({ section: "year-10" });
  //   }
  // }

  // yearElevenSection(isVisible) {
  //   if (isVisible) {
  //     this.setState({ section: "year-11" });
  //   }
  // }

  // yearTwelveSection(isVisible) {
  //   if (isVisible) {
  //     this.setState({ section: "year-12" });
  //   }
  // }

  // yearThirteenSection(isVisible) {
  //   if (isVisible) {
  //     this.setState({ section: "year-13" });
  //   }
  // }

  // yearFourteenSection(isVisible) {
  //   if (isVisible) {
  //     this.setState({ section: "year-14" });
  //   }
  // }

  // yearFifteenSection(isVisible) {
  //   if (isVisible) {
  //     this.setState({ section: "year-15" });
  //   }
  // }

  // yearSixteenSection(isVisible) {
  //   if (isVisible) {
  //     this.setState({ section: "year-16" });
  //   }
  // }

  // yearSeventeenSection(isVisible) {
  //   if (isVisible) {
  //     this.setState({ section: "year-17" });
  //   }
  // }

  // yearEighteenSection(isVisible) {
  //   if (isVisible) {
  //     this.setState({ section: "year-18" });
  //   }
  // }

  // futureSection(isVisible) {
  //   if (isVisible) {
  //     this.setState({ section: "future" });
  //   }
  // }

  formatSection(section) {
    let formattedTitle;

    if (section === "today" || section === "before") {
      formattedTitle = section;
    } else if (section === "future") {
      formattedTitle = "The Future"
    } else {
      let stringArray = section.split("-");
      formattedTitle = stringArray.join(" ");

      switch (formattedTitle) {
        case "year 1":
          formattedTitle += " - 2005";
          break;
        case "year 2":
          formattedTitle += " - 2006";
          break;
        case "year 3":
          formattedTitle += " - 2007";
          break;
        case "year 4":
          formattedTitle += " - 2008";
          break;
        case "year 5":
          formattedTitle += " - 2009";
          break;
        case "year 6":
          formattedTitle += " - 2010";
          break;
        case "year 7":
          formattedTitle += " - 2011";
          break;
        case "year 8":
          formattedTitle += " - 2012";
          break;
        case "year 9":
          formattedTitle += " & 10 - 2013-2014";
          break;
        case "year 11":
          formattedTitle += " - 2015";
          break;
        case "year 12":
          formattedTitle += " - 2016";
          break;
        case "year 13":
          formattedTitle += " - 2017";
          break;
        case "year 14":
          formattedTitle += " - 2018";
          break;
        case "year 15":
          formattedTitle += " - 2019";
          break;
        case "year 16":
          formattedTitle += " - 2020";
          break;
        case "year 17":
          formattedTitle += " - 2021";
          break;
        case "year 18":
          formattedTitle += " - 2022";
          break;
        case "year 19":
          formattedTitle += " - 2023";
          break;
        default:
          break;
      }
    }

    return formattedTitle;
  }

  render() {
    let mobileView = this.state.mobileView;
    let currentSection = this.formatSection(this.state.section);

    return (
      <React.Fragment>
        <Helmet>
          <title>Green Port Timeline</title>
        </Helmet>

        <ParallaxProvider>
          <Icons />
          <div className="gp-page">
            <Link to="/port-info/mission-vision#the-green-port-through-the-years" className="ie-exit"><svg className="icon icon--close"><use xlinkHref="#icon-close" /></svg></Link>
            <div className={`mobile-nav-container ${this.state.mobileNavOpen ? "" : "closed"}`}>
              <div className="section-container">
                <Link to="/port-info/mission-vision#the-green-port-through-the-years" className="green-port-close-mobile"><svg className="icon icon--close"><use xlinkHref="#icon-close" /></svg></Link>
                <p>The Green Port Through the Years</p>
                <button type="button" onClick={this.toggleNav} className="nav-button active-section capitalize">{currentSection}<i className="next-year"></i></button>
              </div>
              <div className={`gp-page__navigation-mobile ${this.state.mobileNavOpen ? "" : "closed"}`}>
                <ul className="gp-page__navigation-mobile--list">
                  <li className={`nav-item today ${this.state.section === "today" ? "active-section" : ""}`}><a href={`#${this.state.section}`} onClick={this.sectionSwitch}>Today</a></li>
                  <li className={`nav-item before ${this.state.section === "before" ? "active-section" : ""}`}><a href={`#${this.state.section}`} onClick={this.sectionSwitch}>Before</a></li>
                  <li className={`nav-item 1 ${this.state.section === "year-1" ? "active-section" : ""}`}><a href={`#${this.state.section}`} onClick={this.sectionSwitch}>Year 1 – 2005</a></li>
                  <li className={`nav-item 2 ${this.state.section === "year-2" ? "active-section" : ""}`}><a href={`#${this.state.section}`} onClick={this.sectionSwitch}>Year 2 – 2006</a></li>
                  <li className={`nav-item 3 ${this.state.section === "year-3" ? "active-section" : ""}`}><a href={`#${this.state.section}`} onClick={this.sectionSwitch}>Year 3 – 2007</a></li>
                  <li className={`nav-item 4 ${this.state.section === "year-4" ? "active-section" : ""}`}><a href={`#${this.state.section}`} onClick={this.sectionSwitch}>Year 4 – 2008</a></li>
                  <li className={`nav-item 5 ${this.state.section === "year-5" ? "active-section" : ""}`}><a href={`#${this.state.section}`} onClick={this.sectionSwitch}>Year 5 – 2009</a></li>
                  <li className={`nav-item 6 ${this.state.section === "year-6" ? "active-section" : ""}`}><a href={`#${this.state.section}`} onClick={this.sectionSwitch}>Year 6 – 2010</a></li>
                  <li className={`nav-item 7 ${this.state.section === "year-7" ? "active-section" : ""}`}><a href={`#${this.state.section}`} onClick={this.sectionSwitch}>Year 7 – 2011</a></li>
                  <li className={`nav-item 8 ${this.state.section === "year-8" ? "active-section" : ""}`}><a href={`#${this.state.section}`} onClick={this.sectionSwitch}>Year 8 – 2012</a></li>
                  <li className={`nav-item 9 ${this.state.section === "year-9" ? "active-section" : ""}`}><a href={`#${this.state.section}`} onClick={this.sectionSwitch}>Year 9 & 10 – 2013-2014</a></li>
                  <li className={`nav-item 10 ${this.state.section === "year-10" ? "active-section" : ""}`}><a href={`#${this.state.section}`} onClick={this.sectionSwitch}>Year 11 – 2015</a></li>
                  <li className={`nav-item 11 ${this.state.section === "year-11" ? "active-section" : ""}`}><a href={`#${this.state.section}`} onClick={this.sectionSwitch}>Year 12 – 2016</a></li>
                  <li className={`nav-item 12 ${this.state.section === "year-12" ? "active-section" : ""}`}><a href={`#${this.state.section}`} onClick={this.sectionSwitch}>Year 13 – 2017</a></li>
                  <li className={`nav-item 13 ${this.state.section === "year-13" ? "active-section" : ""}`}><a href={`#${this.state.section}`} onClick={this.sectionSwitch}>Year 14 – 2018</a></li>
                  <li className={`nav-item 14 ${this.state.section === "year-14" ? "active-section" : ""}`}><a href={`#${this.state.section}`} onClick={this.sectionSwitch}>Year 15 – 2019</a></li>
                  <li className={`nav-item 15 ${this.state.section === "year-15" ? "active-section" : ""}`}><a href={`#${this.state.section}`} onClick={this.sectionSwitch}>Year 16 – 2020</a></li>
                  <li className={`nav-item 15 ${this.state.section === "year-16" ? "active-section" : ""}`}><a href={`#${this.state.section}`} onClick={this.sectionSwitch}>Year 17 – 2021</a></li>
                  <li className={`nav-item 15 ${this.state.section === "year-17" ? "active-section" : ""}`}><a href={`#${this.state.section}`} onClick={this.sectionSwitch}>Year 18 – 2022</a></li>
                  <li className={`nav-item 15 ${this.state.section === "year-18" ? "active-section" : ""}`}><a href={`#${this.state.section}`} onClick={this.sectionSwitch}>Year 19 – 2023</a></li>
                  <li className={`nav-item future ${this.state.section === "future" ? "active-section" : ""}`}><a href={`#${this.state.section}`} onClick={this.sectionSwitch}>The Future</a></li>
                </ul>
              </div>
            </div>

            <div className="gp-page__inner">
              {/* <VisabilitySensor onChange={this.todaySection} partialVisibility scrollCheck={true} resizeCheck={true}> */}
                <section id="today" className="gp-page__section gp-page__section--header">
                  <div className="title-container gutter">
                    <h2 className="today gp-title">The Green Port <br />Through the Years</h2>
                    <p className="gp-subtitle intro">Today, the Port’s business success continues, and the environmental programs that began in Long Beach have been copied by seaports all over the world. See how we got there.</p>
                  </div>

                  <div className="gp-page__next-section">
                    <h3 className="year-text">Before</h3>
                    <i className="next-year"></i>
                  </div>

                  <img src={header} alt="Green Port vessel" className="header-image" />
                </section>
              {/* </VisabilitySensor> */}

              {/* Intro Component */}
              {/* <VisabilitySensor onChange={this.beforeSection} partialVisibility offset={mobileView ? { bottom: 400 } : { bottom: 600, top: 600 }} scrollCheck={true} resizeCheck={true}> */}
                <section id="before" className="gp-page__section gp-page__section--intro">
                  <h3 className="before gp-title gutter">Before the Green Port</h3>

                  <p className="gp-text intro gutter">In the early 2000s, the Port of Long Beach was growing. A new mega-terminal was opening on Pier T, the former Naval Station property, and the Alameda Corridor, a major infrastructure upgrade connecting the Port complex to rail yards in Los Angeles, was near completion.</p>

                  <div className="text-container gutter">
                    <p className="gp-text intro">However, that growth came at a cost. A study by the South Coast Air Quality Management District found that diesel emissions were adversely impacting the health of people living along major transportation corridors, especially where trucks traveled.</p>

                    <p className="gp-text intro">As the ports of Long Beach and Los Angeles looked to make further improvements to ensure they remained competitive regional economic engines in the new century, two projects, one at China Shipping's terminal in Los Angeles and another at Pier J, were blocked for insufficient air quality improvements.</p>
                  </div>

                  <p className="gp-ending-text intro gutter">The time had come for change. <br />How did the Port evolve into an environmental leader?</p>

                  <div className="gp-page__next-section">
                    <h3 className="year-text">Year 1</h3>
                    <i className="next-year"></i>
                  </div>
                </section>
              {/* </VisabilitySensor> */}

              {/* Year 1 Component */}
              {/* <VisabilitySensor onChange={this.yearOneSection} partialVisibility scrollCheck={true} resizeCheck={true}> */}
                <GreenPort2005 />
              {/* </VisabilitySensor> */}

              {/* Year 2 Component */}
              {/* <VisabilitySensor onChange={this.yearTwoSection} partialVisibility scrollCheck={true} resizeCheck={true}> */}
                <GreenPort2006 />
              {/* </VisabilitySensor> */}

              {/* Year 3 Component */}
              {/* <VisabilitySensor onChange={this.yearThreeSection} partialVisibility scrollCheck={true} resizeCheck={true}> */}
                <GreenPort2007 />
              {/* </VisabilitySensor> */}

              {/* Year 4 Component */}
              {/* <VisabilitySensor onChange={this.yearFourSection} partialVisibility scrollCheck={true} resizeCheck={true}> */}
                <GreenPort2008 />
              {/* </VisabilitySensor> */}

              {/* Year 5 Component */}
              {/* <VisabilitySensor onChange={this.yearFiveSection} partialVisibility scrollCheck={true} resizeCheck={true}> */}
                <GreenPort2009 />
              {/* </VisabilitySensor> */}

              {/* Year 6 Component */}
              {/* <VisabilitySensor onChange={this.yearSixSection} partialVisibility scrollCheck={true} resizeCheck={true}> */}
                <GreenPort2010 />
              {/* </VisabilitySensor> */}

              {/* Year 7 Component */}
              {/* <VisabilitySensor onChange={this.yearSevenSection} partialVisibility scrollCheck={true} resizeCheck={true}> */}
                <GreenPort2011 />
              {/* </VisabilitySensor> */}

              {/* Year 8 Component */}
              {/* <VisabilitySensor onChange={this.yearEightSection} partialVisibility scrollCheck={true} resizeCheck={true}> */}
                <GreenPort2012 />
              {/* </VisabilitySensor> */}

              {/* Year 9 Component */}
              {/* <VisabilitySensor onChange={this.yearNineSection} partialVisibility scrollCheck={true} resizeCheck={true}> */}
                <GreenPort2014 />
              {/* </VisabilitySensor> */}

              {/* Year 10 Component */}
              {/* <VisabilitySensor onChange={this.yearTenSection} partialVisibility scrollCheck={true} resizeCheck={true}> */}
                <GreenPort2015 />
              {/* </VisabilitySensor> */}

              {/* Year 11 Component */}
              {/* <VisabilitySensor onChange={this.yearElevenSection} partialVisibility scrollCheck={true} resizeCheck={true}> */}
                <GreenPort2016 />
              {/* </VisabilitySensor> */}

              {/* Year 12 Component */}
              {/* <VisabilitySensor onChange={this.yearTwelveSection} partialVisibility scrollCheck={true} resizeCheck={true}> */}
                <GreenPort2017 />
              {/* </VisabilitySensor> */}

              {/* Year 13 Component */}
              {/* <VisabilitySensor onChange={this.yearThirteenSection} partialVisibility scrollCheck={true} resizeCheck={true}> */}
                <GreenPort2018 />
              {/* </VisabilitySensor> */}

              {/* Year 14 Component */}
              {/* <VisabilitySensor onChange={this.yearFourteenSection} partialVisibility scrollCheck={true} resizeCheck={true}> */}
                <GreenPort2019 />
              {/* </VisabilitySensor> */}

              {/* Year 15 Component */}
              {/* <VisabilitySensor onChange={this.yearFifteenSection} partialVisibility scrollCheck={true} resizeCheck={true}> */}
                <GreenPort2020 />
              {/* </VisabilitySensor> */}

              {/* Year 16 Component */}
              {/* <VisabilitySensor onChange={this.yearSixteenSection} partialVisibility scrollCheck={true} resizeCheck={true}> */}
                <GreenPort2021 />
              {/* </VisabilitySensor> */}

              {/* Year 17 Component */}
              {/* <VisabilitySensor onChange={this.yearSeventeenSection} partialVisibility scrollCheck={true} resizeCheck={true}> */}
                <GreenPort2022 />
              {/* </VisabilitySensor> */}

              {/* Year 18 Component */}
              {/* <VisabilitySensor onChange={this.yearEighteenSection} partialVisibility scrollCheck={true} resizeCheck={true}> */}
                <GreenPort2023 />
              {/* </VisabilitySensor> */}

              {/* Future Component */}
              {/* <VisabilitySensor onChange={this.futureSection} partialVisibility scrollCheck={true} resizeCheck={true}> */}
                <section id="future" className="gp-page__section gp-page__section--future">
                  <div className="gutter">
                    <h2 className="future gp-title gp-page__section--title">The Future</h2>

                    <p className="gp-text intro">After almost 20 years of the Green Port, marine life in the harbor is thriving, with the most recent survey showing the highest recorded biodiversity of the four previous studies of the San Pedro Bay Harbor.</p>

                    <p className="gp-text intro">Looking ahead, the Port has turned its focus to reducing greenhouse gases in an attempt to stave off climate change, with targets of 40% reduction below 1990 levels by 2030 and 80% reduction below 1990 levels by 2050.</p>

                    <p className="gp-text intro">Capital improvements over the next decade include an extensive rail support facility to expand the Port’s on-dock rail capacity, moving cargo more efficiently and reducing truck trips. New technologies are constantly being tested on and near the docks as the Port reaches for zero-emissions cargo handling by 2030 and a zero-emissions drayage truck fleet by 2035 – when Long Beach will celebrate 30 years as The Green Port.</p>
                  </div>

                  <img src={futureWildlife} alt="cranes and seals" className="section-image" />

                  <img src={futureFooter} alt="zero emissions by 2035" className="section-image" />
                </section>
              {/* </VisabilitySensor> */}
            </div>
          </div>
        </ParallaxProvider>
      </React.Fragment>
    )
  }
}

export default GreenPort;