import React from "react";
import { Parallax } from "react-scroll-parallax";

import header from "../images/port-info/green-port/2014-header.jpg";
import tray from "../images/port-info/green-port/2014-tray.jpg";
import secondLayer2014 from "../images/port-info/green-port/2014-layer-2.png";
import thirdLayer2014 from "../images/port-info/green-port/2014-layer-3.png";

class GreenPort2014 extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <section id="year-9" className="gp-page__section gp-page__section--year-9 gutter">
        {/* <h2 className="gp-title gp-page__section--title">Year 9</h2> */}

        <h2 className="year-9 gp-page__section--year-title">2013 <br className="reverse-break" />- 2014</h2>

        <div className="gp-page__section--header-container">
          {/* <h2 className="year-9">2014</h2> */}
          <img src={header} alt="aquatic view" className="section-image header-image year-9-image" />

          <div className="parallax-container">
            <Parallax tagInner="figure" y={[0, 0]} className="parallax-container__background">
              <img src={thirdLayer2014} />
            </Parallax>
            <Parallax tagInner="figure" y={[40, -10]} className="parallax-container__midground section-8-layer-2">
              <img src={secondLayer2014} />
            </Parallax>
          </div>
        </div>

        <p className="gp-text intro">A survey conducted in 2013 and 2014 identifies 558 species of plants and animals living on the rocks and pilings in San Pedro Bay harbors. It represents a 60% increase from the last survey in 2008 and almost twice the number cataloged in a 2000 survey.</p>

        <img src={tray} alt="wildlife tray" className="section-image" />

        <p className="gp-text intro">Water quality conditions have also improved, with oxygen and phytoplankton measurements higher than ever before. Fish are abundant, and giant kelp beds have expanded to cover as much as 132 acres of Outer Harbor waters. Maximum kelp coverage reached only 27 acres in 2000 and 80 acres in 2008.</p>

        <p className="gp-text intro">The new Port of Long Beach Maintenance Building is certified LEED Gold. When it opens, it has a solar array that is the third-largest in Long Beach, at 323 kilowatts.</p>

        <div className="gp-page__next-section">
          <h3 className="year-text">Year 11</h3>
          <i className="next-year"></i>
        </div>
      </section>
    )
  }
}

export default GreenPort2014;