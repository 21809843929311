import React from "react";
import { Parallax } from "react-scroll-parallax";

import boat from "../images/port-info/green-port/2007-boat.jpg";
import header from "../images/port-info/green-port/2007-header.png";
import bkg from "../images/port-info/green-port/2007-globe-bkg.jpg";
import firstLayer2007 from "../images/port-info/green-port/2007-layer-1.png";
import secondLayer2007 from "../images/port-info/green-port/2007-layer-2.png";
import thirdLayer2007 from "../images/port-info/green-port/2007-layer-3.png";

class GreenPort2007 extends React.Component {
  constructor(props) {
    super(props);
    // this.handleWindowSizeChange = this.handleWindowSizeChange.bind(this);
    // this.layerTwoOffset = this.layerTwoOffset.bind(this);
    // this.layerThreeOffset = this.layerThreeOffset.bind(this);
  }

  // state = {
  //   windowWidth: null
  // }

  // componentDidMount() {
  //   if (typeof window !== 'undefined') {
  //     this.handleWindowSizeChange();
  //     window.addEventListener('resize', this.handleWindowSizeChange);
  //   }
  // }
  // componentWillUnmount() {
  //   window.removeEventListener('resize', this.handleWindowSizeChange);
  // }

  // handleWindowSizeChange() {
  //   this.setState({ windowWidth: window.innerWidth });
  // }

  // layerTwoOffset(windowWidth) {
  //   let coordinateArray;

  //   if (windowWidth < 1075) {
  //     coordinateArray = [10, 0];
  //   } else if (windowWidth >= 1075) {
  //     coordinateArray = [25, -10];
  //   }

  //   return coordinateArray;
  // }

  // layerThreeOffset(windowWidth) {
  //   let coordinateArray;

  //   if (windowWidth < 912) {
  //     coordinateArray = [30, -10];
  //   } else if (windowWidth >= 912) {
  //     coordinateArray = [50, 10];
  //   } else if (windowWidth >= 1075) {
  //     coordinateArray = [100, 0];
  //   }

  //   return coordinateArray;
  // }

  render() {
    return (
      <section id="year-3" className="gp-page__section gp-page__section--year-3">
        {/* <h2 className="gp-title gp-page__section--title">Year 3</h2> */}

        <h2 className="year-3 gp-page__section--year-title">2007</h2>

        <div className="gp-page__section--header-container">
          {/* <img src={bkg} alt="background canvas" className="section-image" /> */}
          {/* <h2 className="year-3">2007</h2> */}
          <img src={header} alt="globe" className="section-image header-image year-3-image" />
          
          <div className="parallax-container">
            <Parallax tagInner="figure" y={[0, 0]} className="parallax-container__background">
              <img src={thirdLayer2007} />
            </Parallax>
            <Parallax tagInner="figure" y={[40, 0]} className="parallax-container__midground">
              <img src={secondLayer2007} />
            </Parallax>
            <Parallax tagInner="figure" y={[120, 0]} className="parallax-container__foreground">
              <img src={firstLayer2007} />
            </Parallax>
          </div>
        </div>

        <div className="gutter">
          <p className="gp-text intro">The ports of Long Beach and Los Angeles initiate the Technology Advancement Program (TAP), a competitive funding program to facilitate the development and demonstration of clean technologies.</p>

          <p className="gp-text intro">Inspired by the CAAP, the ports of Seattle, Tacoma and Vancouver adopt the Northwest Ports Clean Air Strategy. Since the Green Port Policy was instituted, ports in China, Mexico and the Panama Canal have also signed memoranda of understanding with Long Beach for environmental exchange programs.</p>

          <img src={boat} alt="boat" className="section-image" />

          <p className="gp-text intro">The Port contributes $500,000 toward the development of the first hybrid tugboat in the world. The funding comes from the Technology Advancement Program created by the CAAP.</p>

          <div className="gp-page__next-section">
            <h3 className="year-text">Year 4</h3>
            <i className="next-year"></i>
          </div>
        </div>
      </section>
    )
  }
}

export default GreenPort2007;