import React from "react";
import { Parallax } from "react-scroll-parallax";

import header from "../images/port-info/green-port/2015-header.jpg";
import firstLayer2015 from "../images/port-info/green-port/2015-layer-1.png";
import secondLayer2015 from "../images/port-info/green-port/2015-layer-2.png";

class GreenPort2015 extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <section id="year-11" className="gp-page__section gp-page__section--year-10">
        {/* <h2 className="gp-title gp-page__section--title">Year 10</h2> */}

        <h2 className="year-11 gp-page__section--year-title">2015</h2>

        <div className="gp-page__section--header-container">
          {/* <h2 className="year-10">2015</h2> */}
          <img src={header} alt="reduction in diesel particulates" className="section-image header-image year-10-image" />

          <div className="parallax-container">
            <Parallax tagInner="figure" y={[0, 0]} className="parallax-container__background">
              <img src={secondLayer2015} />
            </Parallax>
            <Parallax tagInner="figure" y={[100, 0]} className="parallax-container__midground section-10-layer-1">
              <img src={firstLayer2015} />
            </Parallax>
          </div>
        </div>

        <div className="gutter">
          <p className="gp-text intro">Ten years after the Green Port Policy was adopted, the Port has seen an 85% reduction in diesel particulates, a 97% reduction in sulfur oxides, a 50% reduction in nitrogen oxides and a 21% reduction in greenhouse gases.</p>

          <div className="gp-page__next-section">
            <h3 className="year-text">Year 12</h3>
            <i className="next-year"></i>
          </div>
        </div>
      </section>
    )
  }
}

export default GreenPort2015;