import React from "react";

import header from "../images/port-info/green-port/2017-header.jpg";
import books from "../images/port-info/green-port/2017-books.jpg";

class GreenPort2017 extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <section id="year-13" className="gp-page__section gp-page__section--year-12 gutter">
        {/* <h2 className="gp-title gp-page__section--title">Year 12</h2> */}

        <h2 className="year-13 gp-page__section--year-title">2017</h2>

        <div className="gp-page__section--header-container">
          <img src={header} alt="2017 view of the port" className="section-image header-image year-12-image minimize" />
        </div>

        <p className="gp-text intro">More than 95% of vessels earn incentives by complying with the Green Flag Program by slowing down ships to 12 knots or less within 20 nautical miles of Point Fermin.</p>

        <p className="gp-text intro">New state regulations take effect mandating at least half of all container ships run on shore-side electricity at berth. The Port has already completed more than $185 million worth of dockside power hookups and other infrastructure to facilitate shore power, reducing emissions.</p>

        <img src={books} alt="stack of books" className="section-image" />

        <p className="gp-text intro">In the latest update to the CAAP, the Ports outline comprehensive strategies for accelerating progress toward a zero-emission future while protecting and strengthening the ports' competitive position in the global economy.</p>

        <div className="gp-page__next-section">
          <h3 className="year-text">Year 14</h3>
          <i className="next-year"></i>
        </div>
      </section>
    )
  }
}

export default GreenPort2017;