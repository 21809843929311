import React from "react";

import header from "../images/port-info/green-port/2020-header.jpg";

class GreenPort2021 extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <section id="year-17" className="gp-page__section gp-page__section--year-16 gutter">
        {/* <h2 className="gp-title gp-page__section--title">Year 15</h2> */}

        <h2 className="year-17 gp-page__section--year-title">2021</h2>

        <div className="gp-page__section--header-container">
          {/* <img src={header} alt="truck driving" className="section-image header-image year-16-image" /> */}
        </div>

        <p className="gp-text intro">For the first time, the Port of Long Beach achieved all of the 2023 emission-reduction goals outlined in the San Pedro Bay Ports Clean Air Action Plan.</p>

        <p className="gp-text intro">The Port’s annual emissions inventory report found diesel particulate matter was down 90%, smog-forming nitrogen oxides decreased 62% and sulfur oxides declined 97%.</p>

        <div className="gp-page__next-section">
          <h3 className="year-text">Year 18</h3>
          <i className="next-year"></i>
        </div>
      </section>
    )
  }
}

export default GreenPort2021;